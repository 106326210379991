// table_controller.js

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];
  open() {
    // Save the original body
    const body = document.body.innerHTML;
    // Display only the content to print
    document.body.innerHTML = this.contentTarget.innerHTML;
    // Open print
    window.print();
    // Restore the original content
    document.body.innerHTML = body;
  }
}