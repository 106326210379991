import { Controller } from "stimulus";

const TARGETS = [ 
  "checkbox",
  "button"
];

export default class extends Controller {
  static targets = TARGETS;

  markAll(e) {

    this.checkboxTargets.forEach(target => {
      if (target) {
        target.checked = e.target.checked;
      }
    });

    this.buttonTarget.disabled = !e.target.checked;
  }

  setButtonState() {
    let buttonDisabled = true;
    this.checkboxTargets.forEach(target => {
      if (target.checked) {
        buttonDisabled = false
      }
    });
    this.buttonTarget.disabled = buttonDisabled;
  }
}